.partner-drivers {
	padding: 20px 0px 0px 0px;
	.partner-drivers__content {
		display: flex;
		gap: 30px;
		flex-direction: column;
		// margin-bottom: 30px;
	}
	.partner-drivers__top {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 20px;
		.my-tickets__export {
			align-self: center;
			margin-top: 0;
		}
		.defaultwrapper__filter {
			margin-bottom: 0;
		}
	}
}
@media screen and (max-width: 870px) {
	.partner-drivers {
		padding: 0;
	}
	.partner-drivers__top {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 20px;
		padding: 0px 15px;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;