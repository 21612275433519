.partner-buses {
	padding: 20px 0 0px 0;
	.partner-buses__content {
		display: flex;
		flex-direction: column;
		gap: 30px;
		// margin-bottom: 30px;
	}
	.partner-buses__top {
		display: flex;
		gap: 20px;
		justify-content: end;
		align-items: center;
	}
	.my-tickets__export {
		margin-top: 0;
		align-self: center;
	}
	.defaultwrapper__filter {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 870px) {
	.partner-buses__top {
		padding: 0px 15px;
	}
	.partner-buses {
		padding: 0;
		// padding: 44px 0px 0px 0px !important;
		.route-item-transport__item {
			padding: 36px 15px !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;