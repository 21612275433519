.history-marches {
	padding: 20px 0 0px 0;
	.history-marches__content {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	.history-marches__top {
		display: flex;
		gap: 20px;
		justify-content: end;
		align-items: center;
	}
	.my-tickets__export {
		margin-top: 0;
		align-self: center;
	}
	.defaultwrapper__filter {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 870px) {
	.history-marches__top {
		padding: 0px 15px;
	}
	.history-marches {
		padding: 44px 0px 0px 0px !important;
		.route-item-transport__item {
			padding: 36px 15px !important;
		}
	}
}

.history-marches {
	&__item {
		.history-marches__top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12px;
			gap: 20px;
		}
		.history-marches__number {
			// padding-bottom: 15px;
			color: #121212;
			font-size: 20px !important;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		.history-marches__manufacturer {
			color: #121212;
			font-size: 20px !important;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.history-marches__info-title {
		color: #121212;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.history-marches__item {
		border-radius: 3px;
		background: #fff;
		padding: 20px 20px;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		p {
			margin: 0;
		}
	}
	.history-marches__content {
		display: flex;
		flex-direction: column;
		gap: 22px;
	}
	.history-marches__info-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	.history-marches__info-main {
		span {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			line-height: normal;
		}
	}
	.history-marches__info-item {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

@media screen and (max-width: 1100px) {
	.history-marches {
		&__item {
			.history-marches__top {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 20px;
			}
		}
		.history-marches__info-main {
			span {
				color: #121212;
				font-size: 14px;
				font-style: normal;
				line-height: normal;
			}
		}
		.history-marches__info-item {
			display: flex;
			flex-direction: column !important;
			gap: 10px;
			flex-wrap: wrap;
			justify-content: flex-start !important;
		}
	}
}
@media screen and (max-width: 630px) {
	.history-marches__row {
		display: flex;
		flex-direction: column;
		gap: 0px;
	}
	.history-marches-modal {
		.ant-modal-title {
			font-size: 16px;
			line-height: 32px;
		}
		.ant-modal-content {
			padding: 15px 15px !important;
		}
		.history-marches__item {
			padding: 20px 15px 30px 15px;
		}
		.history-marches__top {
			flex-direction: column;
		}
	}
}

@media screen and (min-width: 695px) /* and (max-width: 1024px) */ {
	.history-marches__info {
		.history-marches__info-item {
			width: 100%;

			flex-direction: row !important;
			align-items: center !important ;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;