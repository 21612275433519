.my-tickets__export {
	display: flex;
	gap: 40px;
	align-items: center;
	align-self: flex-end;
	.my-tickets__export-buttons {
		button {
			margin-right: 10px;
			padding: 0;
			background-color: transparent;
			border: none;
		}
	}
	.my-tickets__export-header {
		flex: auto 0;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;