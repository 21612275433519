.transactiondata-all-div {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 0px 0px 0px;
	.ant-pagination-prev {
		flex: auto 0 !important;
	}

	padding-top: 20px;
	.partner-routes__content {
		display: flex;
		gap: 10px;
		flex-direction: column;
		// margin-bottom: 30px;
	}
	.partner-routes__top {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 20px;
		margin-bottom: 20px;

		.my-tickets__export {
			align-self: center;
			margin-top: 0;
		}
		.defaultwrapper__filter {
			margin-bottom: 0;
		}
	}
	.ant-spin-container {
		display: flex;
		flex-direction: column;
	}
	.ant-table-wrapper .ant-table-cell,
	.ant-table-wrapper .ant-table-thead > tr > th {
		padding: 5px !important;
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: center;
	}
	.ant-table-thead {
		width: 100% !important;
		max-width: 120px !important;
		.ant-table-cell {
			color: #009e83 !important;
			max-width: 100px !important;
			text-align: center !important;
		}
	}

	.ant-table-tbody {
		width: 100% !important;
		max-width: 120px !important;
		.ant-table-cell {
			color: black !important;
			text-align: center !important;
		}
	}
	.ant-table-wrapper .ant-table-tbody > tr > td {
		padding: 5px !important;
		font-size: 13px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: center;
	}
	.ant-table-wrapper .ant-table-pagination.ant-pagination {
		margin-top: 50px !important;
	}
	.ant-pagination-item-link {
		position: relative;
		span {
			position: absolute;
			right: 10px;
			top: 10px;
		}
		.ant-pagination .ant-pagination-item-active {
			width: 50px !important;
			height: 50px !important;
			border-radius: 50% !important;
		}
	}
	.terminal {
		color: #009e83;
		text-decoration: underline;
		cursor: pointer;
	}
}

@media screen and (max-width: 870px) {
	.partner-routes__top {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 20px;
		padding: 0px 15px;
		margin-bottom: 20px;
	}
	.transactiondata-all-div {
		padding: 0 !important;
	}
}
.transaction-data {
	.transaction-data__item-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.transaction-data__item-info {
			font-weight: 700;
			font-size: 20px;
		}
	}
	.transaction-data-row {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.drivers-modal-bottom,
	.drivers-modal-top {
		.transaction-data__item-main {
			justify-content: space-between;
		}
	}
	.transaction-data__item {
		padding: 20px 20px 20px 20px;
		border-radius: 3px;
		background: #fff;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		.transaction-data__item-main {
			display: flex;
			align-items: center;

			.transaction-data__item-title {
				margin-right: 10px;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.transaction-data__item-info {
				color: #121212;
				font-size: 14px;
				text-align: start;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
.route-item-transport-modal.drivers {
	.ant-modal-title {
		color: #121212;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
}

@media screen and (max-width: 820px) {
	.route-item-transport-modal.drivers {
		.transaction-data .drivers-modal-top {
			max-width: none;
		}
		.ant-modal-title {
			font-size: 18px;
		}

		.transaction-data__item-row.drivers-modal-bottom {
			.transaction-data__item-main {
				flex-direction: column;
				gap: 10px;
				align-items: start;
			}
		}
	}
	.transaction-data {
		.transaction-data__item {
			padding: 20px 15px 20px 15px !important;
		}
	}
}

@media screen and (max-width: 1010px) {
	.transaction-data {
		.transaction-data__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.transaction-data__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.transaction-data__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.transaction-data__item-info {
			margin: 10px 0px;
		}
	}
}

@media screen and (max-width: 780px) {
	.transaction-data {
		.transaction-data__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.transaction-data__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.transaction-data__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.transaction-data__item-info {
			margin: 10px 0px;
		}
	}
}

@media screen and (min-width: 695px) and (max-width: 869px) {
	.transaction-data {
		.transaction-data__item-main {
			width: 100%;

			flex-direction: row !important;
			align-items: center !important;
			.transaction-data__item-info {
				margin: 0;
			}
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;