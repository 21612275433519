.ant-menu .ant-menu-item {
	border-radius: 0 !important;
	margin: 0;

	.ant-menu .ant-menu-submenu-title {
		border-radius: 0;
	}
	.ant-menu-submenu-title:hover {
		background-color: transparent !important;
	}
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
	border-inline-end: 0;
}
.ant-menu-submenu-arrow,
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
	display: none !important;
}
.header-lang {
	.lang-link {
		border: none;
		margin: 0;
		padding: 0 0 1px 0;
	}
}
.menu-login {
	.ant-menu {
		width: 300px !important;
	}
}

.header {
	.ant-btn.bell-header {
		padding: 0 !important;
	}
	.ant-menu-submenu::after {
		border-bottom: none !important;
	}
	.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
		color: #009e83;
		font-weight: bold !important;
	}

	position: sticky;
	top: 0;
	z-index: 500 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	border-bottom: 1px solid rgba(17, 16, 16, 0.1);
	box-sizing: border-box;
	overflow: hidden;

	padding: 0;
	height: 100px;
	.ant-menu-item-selected {
		font-weight: 800;
	}
	.content_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		max-width: 1300px;
		padding: 0 30px;
		height: 100%;
	}
	.logo_link {
		display: flex;
		align-items: center;
		width: 150px;
		height: 70px;
		.logo_link__logo {
			max-height: 70px;
		}
	}
	.sign {
		display: flex;
		gap: 4px;
		font-weight: 700;
		cursor: default;
		& span:hover {
			color: @primary-color;
			cursor: pointer;
			transition: 0.3s;
		}
	}
}
.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
	padding: 10px 20px !important;
}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item {
	border-bottom: 1px solid rgba(211, 211, 211, 1);
	padding: 0 0px !important;
	background-color: transparent !important;
}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item:last-child {
	border-bottom: none;
	font-weight: 100 !important;
}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item:nth-last-child(2) {
	border-bottom: 1px solid #000;
}
.ant-menu-light > .ant-menu .ant-menu-item-selected * {
	font-weight: 800;
	background-color: transparent;
	color: #009e83;
}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item:hover {
	color: #009e83 !important;
}

.ant-menu-title-content {
	margin: 20px 0px;
}

.menu-logged {
	max-width: 80px;
	& .ant-menu-item-only-child {
		color: transparent;
	}
	& ::after {
		border-color: unset;
		display: none;
	}
	&.ant-menu {
		border-bottom: unset;
	}
}
.loged_in {
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid;
	height: max-content;
	padding: 6px 6px 6px 15px;
	display: flex;
	border-radius: 60px;
	.ic {
		width: 31px;
		height: 31px;
		border-radius: 50%;
		border: 1.5px solid;
		margin-left: 30px;
		max-width: 31px;
	}
	.name {
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		border-radius: 50%;
		background-color: @primary-color;
		font-weight: bold;
		color: #fff;
		letter-spacing: 1px;
		margin-left: 30px;
		max-width: 31px;
	}
}

.padding {
	padding: 10px;
}

.menu {
	border-bottom: none;
	font-size: 14px;
	& .ant-menu-item::after {
		display: none;
	}
}

.right-actions {
	display: flex;
	align-items: center;
	padding-right: 20px;
	& > * {
		margin-left: 8px !important;
	}
	.lang-link {
		cursor: pointer;
		width: 30px;
		display: flex;
		align-items: center;
		height: 100%;
		font-weight: 600;
		color: @ui-black;
	}
	.profile-link {
		display: flex;
		align-items: center;
		height: 100%;
		color: @ui-black;
	}
}

.lang-dropdown {
	padding: 10px 0 !important;
	li {
		padding: 10px 20px;
	}
}

.profile-dropdown {
	padding: 10px 0 !important;
	width: 200px;
	li {
		padding: 10px 20px;
		color: @ui-black;
	}
}
.ant-drawer-header.ant-drawer-header-close-only {
	display: none;
}

.ant-drawer-mask {
	background: transparent !important;
}
.ant-drawer .ant-drawer-body {
	padding: 20px;
	.ant-menu {
		margin-bottom: 10px;
		.ant-menu-item {
			padding-left: 0 !important;
		}
		.ant-menu-submenu-title {
			color: #000;
			background-color: transparent !important;
			padding: 0 !important;
		}
		.ant-menu-item[path='/news/2'],
		.ant-menu-item[path='/news/1'] {
			padding-left: 20px !important;
		}
	}
}
.ant-drawer-content-wrapper {
	margin-top: 90px;
	box-shadow: 10px 50px 100px 0px rgba(18, 18, 18, 0.1) !important;
}
@media screen and (max-width: 1200px) {
	.ant-menu {
		.ant-menu-item {
			display: flex;
			padding: 0;
			border-bottom: 1px solid #d3d3d3;
			&[path='/'] {
				display: none;
			}
		}
	}
	.ant-divider {
		margin: 15px 0 !important;
	}
	.sign {
		display: flex;
		flex-direction: column;
		color: #121212;
		font-size: 14px;
		font-style: normal;
		line-height: normal;
		margin-bottom: 20px;
	}
	.ant-menu-light .ant-menu-item-selected {
		background-color: transparent !important;
	}
	.lang-link {
		margin-bottom: 25px;
		display: flex;
		gap: 7px;
		padding: 0 0 15px 0;
		border-bottom: 1px solid #000;
		& .lang-ico {
			width: 20px;
			img {
				max-width: 100%;
			}
		}
		p {
			position: relative;
			top: 1px;
			margin: 0;
			align-items: center;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;