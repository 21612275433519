.complaints-box {
	display: flex;
	flex-direction: column;

	.notification-form {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-self: flex-end;
		padding-top: 23px !important;
		.ant-form-item-control-input-content {
			width: 250px;
		}
	}
	.complaint__plus-icon {
		color: #009e83 !important;
	}
	.add-complaint-button {
		width: 54px;
		height: 54px;
		border-radius: 3px;
		display: flex;
		align-items: center;
		border-color: #1bab8e;
		transition: 0.3s;
		&:hover {
			background-color: #009e83;
			.complaint__plus-icon {
				color: white !important;
			}
		}
	}
	.complaints {
		width: 100%;
		height: auto;
		border-radius: 3px;
		background: #fff !important;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05) !important;
		padding: 20px 20px !important;
		margin-bottom: 40px !important;
		height: auto;
		border-radius: 3px !important;
		margin-bottom: 30px;
		.ant-collapse {
			padding: 10px;
		}
		.ant-collapse {
			& > .ant-collapse-item {
				border-bottom: unset !important ;
				border: 1px solid #009e83 !important;
				margin-bottom: 10px !important;
			}
		}

		.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
			position: absolute;
			right: 0;
			top: -4px;
			width: 45px;
			height: 70px;
			color: #009e83;
			margin-left: 40px !important;
			background: transparent !important;
		}
		.label {
			word-wrap: break-word !important;
			.lable-createdAd {
				height: 16px;
				color: grey;
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				letter-spacing: 0px;
				text-align: left;
			}
			.lable-text {
				word-wrap: break-word;
				word-break: break-all; /* This can be useful for forcing breaks */
				font-size: 14px;
				font-weight: 700;
				text-align: left;
			}
			.lable-status {
				color: #121212;
				margin-right: 15px;
				margin-top: 15px;
				height: 30px;
				background-color: transparent;
				border-radius: 15px;
				text-align: center;
				border: 1px solid #009e83;
			}
		}

		.ant-collapse > .ant-collapse-item > .ant-collapse-header {
			width: 100%;
			// max-width: 722px;
			min-height: 71px;
			border-radius: 3px !important;
		}
		.errormeseges {
			display: flex;
			align-items: flex-end;
			background-color: #f0f0f0;
			color: red;
			padding: 8px;
			padding-left: 35px;
		}
		.complaints-item-content {
			display: flex;
			flex-direction: column;
			padding: 20px 10px;
			color: var(--main-black, #121212);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			background-color: #f0f0f0 !important;
			overflow-y: auto;
			height: 500px;

			.maseges-text-exp {
				width: 100%;
				background: #ffffff;
				word-wrap: break-word;
				max-width: 456px;
				padding: 10px;
				margin: 0;
				flex: 0;
				margin-bottom: 10px;
				.header-maseges {
					margin: 0;
					// max-height: 60px;
				}
				.senderName {
					color: #009e83;
					width: 204px;
					height: 16px;
					padding-right: 10px;
					font-size: 14px;
					font-weight: 700;
					letter-spacing: 0px;
				}
				.data-munth-year {
					width: 200px !important;
					height: 16px;
					color: #9e9e9e;
					font-size: 14px;
					font-weight: 400;
					line-height: 36px;
				}
				.maseges-text-msg {
					margin: 0;
					padding: 0;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;
				}

				.read-more {
					margin: 0;
					font-size: 11px;
					font-weight: 700;
					line-height: 36px;
					letter-spacing: 0px;
					color: #009e83;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}

		.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
			background-color: transparent !important;
		}
	}
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
	width: 200px !important;
}

.add-complaint-modal {
	.ant-modal-header {
		margin-bottom: 15px;
		padding: 16px 0;
		text-align: center;
		border-radius: 12px 12px 0px 0px;
		background: #fff;
		box-shadow: 0px 10px 30px 0px rgba(18, 18, 18, 0.08);
		.ant-modal-title {
			margin: 0;
		}
	}
	.ant-modal-body {
		padding: 0 20px;
	}
	.ant-modal-content {
		background: #f3f3f3;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		padding: 0;
		padding-bottom: 20px;
		border-radius: 12px;
	}
	.ant-modal-title {
		margin-bottom: 30px;
		background-color: #fff;
		font-size: 20px;
	}
}
.account__report__modal {
	display: flex;
	justify-content: space-around;
	gap: 20px;
	.account__report__submit {
		.ant-btn {
			height: 57px;
			background-color: #fff;
			border-radius: 3px;
			background: #009e83;
			transition: 0.3s;
			border: none;
			&:hover {
				border: 1px solid #009e83;
				background-color: #fff;
				span {
					color: #009e83;
				}
			}
			span {
				color: #f3f3f3;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				display: block;
			}
		}
	}
	.account__report__cancel {
		.ant-btn {
			height: 57px;
			background-color: #fff;
			border-radius: 3px;
			background: #fff;
			transition: 0.3s;
			&:hover {
				background-color: #009e83;
				span {
					color: #fff;
				}
			}
			span {
				color: #009e83;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				display: block;
			}
		}
	}
	.account__report__submit,
	.account__report__cancel {
		width: 100%;
	}
	.account__report__cancel .ant-btn,
	.account__report__submit .ant-btn {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.ant-upload-list-item {
		align-items: flex-start;
	}

	.ant-upload-list-item-name {
		width: 100%;
		margin-top: 8px;
	}
}

@media (max-width: 867px) {
	.complaints-box {
		.ant-collapse-borderless {
			padding: 20px 10px !important;
		}
		.notification-form {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.complaints-box .complaints .ant-collapse > .ant-collapse-item {
		margin-bottom: 0 !important;
	}
	.complaints {
		.ant-collapse-content-box {
			padding-bottom: 0 !important;
		}
		margin-left: 0px !important;
		padding: 0px 10px;
	}
	.complaints-box .complaints {
		padding: 20px 5px !important;
		padding-bottom: 0px !important;
		.ant-collapse {
			padding: 0px !important;
		}
	}
}

@media (max-width: 438px) {
	.header-maseges {
		height: 70px;
		.senderName {
			display: block;
		}
		.data-munth-year {
			display: block;
			margin-top: 10px;
		}
	}
}

@media screen and (max-width: 470px) {
	.complaints-box .complaints .ant-collapse > .ant-collapse-item {
		border-bottom: unset !important ;
		border: none !important;
		box-shadow: 0 0 3px gray;
	}
	.account__report__modal {
		align-items: center;
		flex-direction: column-reverse;
		gap: 15px;
	}
	.lable-status {
		color: #121212;
		margin-right: 5px !important;
		margin-top: 45px !important;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;