.my-tickets {
	.my-tickets__content {
		padding: 20px 0 50px 0;
	}
	.owned-ticket__header {
		p {
			margin: 0 0 20px 0 !important;
		}
	}
	.my-tickets__owned {
		width: 100%;
	}
	.my-tickets__top {
		display: flex;
		width: 100%;
		margin-bottom: 20px;
		.defaultwrapper__filter {
			margin: 0;
		}
		.my-tickets__export {
			align-self: center;
			margin: 0;
		}
	}
	.my-tickets__row {
		display: flex;
		align-items: end;
		flex-direction: column;
	}
	.my-tickets__owned .owned-ticket:not(:last-child) {
		margin-bottom: 30px;
	}

	.my-tickets__filter {
		margin-bottom: 30px;

		.ant-btn {
			border-radius: 3px;
			border: 1px solid #009e83;
			display: flex;
			align-items: center;
			gap: 15px;
			padding: 25px 10px;
			color: #009e83;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}
	}
}
.ant-picker-footer > a {
	color: #009e83 !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
}

@media screen and (max-width: 480px) {
	.my-tickets {
		.my-tickets__top {
			justify-content: end;
			gap: 30px;
			.my-tickets__export {
				align-self: center;
			}
		}
	}
	.my-tickets__content {
		padding: 20px 20px 50px 20px;
	}
}

@media screen and (max-width: 992px) {
	.ant-collapse-content-box {
		margin: 0;
	}

	.my-tickets {
		.my-tickets__owned {
			display: flex;
			flex-direction: column;
		}
		.owned-ticket:not(:last-child):after {
			content: '';
			margin: 40px 0 0 0;
			width: 100%;
			height: 1px;
			background-color: #009e83;
			display: block;
		}
		.main__ticket-container {
			border: 1px solid #009e83;
		}
		.ticket__transports {
			display: block;
		}
		.my-tickets__content {
			padding-top: 20px !important;
		}
		.owned-ticket {
			box-shadow: none;

			padding: 0 !important;
		}
	}
}
@media screen and (max-width: 869px) {
	.my-tickets__top {
		display: flex;
		width: 100%;
		margin-bottom: 20px;
		padding: 0px 15px;
		.defaultwrapper__filter {
			margin: 0;
		}
		.my-tickets__export {
			align-self: center;
			margin: 0;
		}
	}
	.my-tickets__owned {
		padding: 0 15px;
	}
	.my-tickets__content {
		padding: 20px 0px 50px 0px !important;
	}
}
@media screen and (min-width: 870px) and (max-width: 991px) {
	.my-tickets__content {
		padding: 20px 0 50px 0;
	}
}

@media screen and (max-width: 450px) {
	.my-tickets {
		.my-tickets__owned {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;