.ant-modal.topUpBalance {
	.ant-modal-body {
		padding: 0 30px !important;
	}
}
.ticket-holders {
	padding: 20px 0px 0px 0;
	.ticket-holder__items {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	&__top {
		display: flex;
		margin-bottom: 20px;
		.my-tickets__export {
			align-self: center;
			margin: 0;
		}
		.defaultwrapper__filter {
			margin: 0;
		}
	}
	.ticket__item-description p {
		margin: 0;
	}
	.ticket-holder__item {
		padding: 40px 50px;
		border-radius: 3px;
		background: #fff;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
	}
	.ticket-holders__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 30px;
		.ant-btn.add-holder {
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #009e83 !important;
			color: #009e83;
			gap: 15px;
			svg {
				flex: auto 0;
			}
		}
	}
	.defaultwrapper__filter {
		align-self: flex-end;
	}

	.ticket-holder__item-columns {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	.ticket-holder__item-column {
		display: flex;
		flex-direction: column;
		.ticket-holder__item-header {
			flex: initial;
			color: #121212;
			font-size: 22px;
			font-style: normal;
			font-weight: 600;
			line-height: 36.4px;
			p {
				margin: 0 !important;
			}
		}
		.ticket-holder__item-top {
			display: flex;
			align-items: center;
			gap: 130px;
			flex: 0 0;
		}
		.ticket-holder-card__reverse {
			display: flex;
			flex-direction: column;
			// align-items: center;
			width: 47px;
		}

		.ticket-holder-card__container {
			display: flex;
			margin-top: 10px;
			gap: 20px !important;
			justify-content: space-between;
		}
	}
	.ticket-holder__item-description {
		.ticket__item-description {
			p {
				color: #121212;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 36.4px; /* 202.222% */
			}
		}

		.more-info__item {
			gap: 20px;
			display: flex;
			justify-content: space-between !important;
			.more-info__title {
				// width: 100%;
				// max-width: 250px;
				// min-width: 230px;
				// flex: auto 0;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 36.4px; /* 260% */

				// .title {
				// 	display: flex;
				// }
				// .title1 {
				// 	display: none;
				// }
			}
			.more-info__data {
				// width: 100%;
				text-align: right;
				// max-width: 230px;
				min-width: 100px;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 36.4px; /* 260% */
				.more-info__data-col p {
					margin: 0;
					max-width: 230px;
					width: 100%;
				}
				.action-button {
					cursor: pointer;
					color: #009e83;
					text-decoration: underline;
				}
			}
		}
	}
	.ticket-holder__actions {
		.ticket-holder__actions-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
		}
		.ticket-holder__action-columns {
			display: flex;
			gap: 10px;
			min-height: 70px;
			.ticket-holder__action-column {
				display: flex;
				align-items: center;
				gap: 5px;
				.action-column__title {
					color: #121212;
					font-style: normal;
					font-weight: 400;
					line-height: 16.8px; /* 140% */
				}
				.action-column__ico {
					flex: 0 0;
					position: relative;
					top: 2px;
				}
				.ant-btn {
					max-width: 100%;
					min-width: 270px;
					padding: 20px 5px;
					color: #009e83;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'GHEA Grapalat';
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					text-transform: uppercase;
					border-color: #009e83;
					&:hover {
						background-color: #009e83;
						color: white;
					}
				}
			}
		}
	}
}
// FLIP event
.ticket-holders {
	.flip-box {
		margin-top: 7px;
		background-color: transparent;
		// width: 300px;
		height: 213px;
		perspective: 1000px;
	}
	.ticket-holder-card__flip {
		flex: unset !important;
	}
	.flip-box-inner {
		position: relative;
		width: 325px;
		height: 100%;
		text-align: center;
		transition: transform 0.8s;
		transform-style: preserve-3d;
	}

	.flip-box.true .flip-box-inner {
		transform: rotateY(180deg);
	}

	.flip-box-front,
	.flip-box-back {
		position: absolute;
		width: 325px;
		height: 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&.notVerificated {
			.transportCard_Front .transportCard_Front__content {
				background-color: #606464;
			}
			.transportCard_Back__content-general {
				background-color: rgba(96, 96, 96, 1);
			}
			.transportCard_Back__content {
				background-color: transparent;
			}
		}
	}

	.flip-box-back {
		transform: rotateY(180deg);
	}
	.card-reverse__title {
		p {
			text-align: center;
			min-width: 80px;
			margin-bottom: 0 !important;
		}
	}
	.card-reverse__main {
		user-select: none;

		.card-reverse__ico {
			transition: 0.3s;
			max-height: 47px;
			&.reversed {
				transform: rotate(180deg);
			}
			svg {
				transition: 0.3s;
				background-color: white;
				border-radius: 50%;
			}
			&:hover {
				svg {
					background-color: #009e83;
					g path {
						stroke: white;
					}
				}
			}
			cursor: pointer;
		}
	}
	.action-button-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 47px;
		height: 47px;
		border: 1px solid #009e83;
		border-radius: 50%;
		cursor: pointer;
		transition: 0.2s;

		svg {
			color: #009e83;
		}
		&:hover {
			background-color: #009e83;
			svg {
				color: #fff;
			}
		}
	}
}
// Popups
.ticket-holder__popup {
	.ant-modal-header {
		margin-bottom: 34px;
		padding: 16px 0;
		text-align: center;
		border-radius: 12px 12px 0px 0px;
		background: #fff;
		box-shadow: 0px 10px 30px 0px rgba(18, 18, 18, 0.08);
	}
	.ant-modal-content {
		border-radius: 10px;
		background: #f3f3f3;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		label {
			color: var(--main-black, #121212);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 26.4px; /* 260% */
		}
		padding: 0;
		padding-bottom: 55px;
	}
	.ant-modal-body {
		padding: 0 90px;
	}
	.ant-modal-footer {
		margin: 0;
		padding: 35px 45px 30px 0;
	}
	.ant-modal-footer {
		padding-bottom: 35px;
		.ant-btn {
			height: 60px;
			width: 99px;
		}
	}
	.ant-btn {
		&.ant-btn-default {
			color: #009e83;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
			border-radius: 3px;
			border: 1px solid #009e83;
			background: #fff;

			&:hover {
				background-color: #009e83 !important;
				color: white !important;
				border: 1px solid #009e83 !important;
			}
		}
		&.ant-btn-primary {
			border-radius: 3px;
			border: 1px solid #009e83;
			background: #009e83;
			color: #f9f9f9;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
			&:hover {
				background-color: white !important;
				color: #009e83 !important;
				border: 1px solid #009e83 !important;
			}
		}
	}
	&.add-ticket-holder {
		.ant-modal-body {
			padding: 0 60px;
		}
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			padding: 0;
			.ant-btn {
				max-width: 150px !important;
				width: 100%;
				text-align: center;
			}
		}
	}
	&.check-balance {
		.ant-modal-header {
			margin-bottom: 25px;
		}
		.ant-modal-footer {
			.ant-btn-default {
				display: none !important;
			}
		}
		.ant-modal-content {
			padding-bottom: 0;
		}
		.ant-modal-body {
			padding: 0 50px;
		}
	}
	&.move-balance {
		.ant-modal-footer {
			padding: 0 90px;
			display: flex;
			justify-content: space-between;
		}
		.ant-btn {
			&.ant-btn-default {
				color: #009e83;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				border-radius: 3px;
				border: 1px solid #009e83;
				background: #fff;
			}
			&.ant-btn-primary {
				border-radius: 3px;
				border: 1px solid #009e83;
				background: #009e83;
				color: #f9f9f9;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
			}
			height: 60px;
			width: 150px;
		}
	}
	&.block-balance {
		.ant-modal-content {
			padding-bottom: 35px;
		}
		.ant-modal-body {
			padding: 0 35px;
			text-align: center;
		}
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			gap: 25px;
			padding: 30px 10px 0 10px;
			.ant-btn {
				width: 170px;
			}
		}
	}
	&.charge-balance {
		.ant-modal-content {
			padding-bottom: 35px;
		}
		.ant-modal-body {
			text-align: center;
			margin-bottom: 46px;
		}
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			padding: 0;
			gap: 25px;
			.ant-btn {
				width: 150px;
			}
		}
	}
	&.change-tariff {
		.ant-modal-content {
			padding-bottom: 35px;
		}
		.ant-modal-body {
			padding: 0 20px;

			text-align: center;
			// margin-bottom: 46px;
			.ant-select-selector {
				.ant-select-selection-item {
					color: #000;
					font-size: 13px !important;
				}
			}
			.main-buttons {
				display: flex;
				gap: 20px;
				align-items: center;
				justify-content: center;
				.ant-btn {
					flex: unset !important;
					width: 170px;
					height: 60px;
					color: #009e83;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					text-transform: uppercase;
					border-radius: 3px;
					border: 1px solid #009e83;
					background: #fff;
				}
				.ant-btn-primary {
					background: #009e83;
					color: #fff;
				}
			}
		}
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			padding: 0;
			gap: 25px;
			.ant-btn {
				width: 150px;
			}
		}
	}
	.ant-select-selector {
		border: none !important;
		box-shadow: none;
		border-radius: 4px;
		background: #fff;
		box-shadow: 0px 10px 20px 0px rgba(254, 80, 80, 0.05);
		&:focus {
			outline: none;
			border: none !important;
		}
		.ant-select-selection-item {
			color: #009e83;
			text-align: start;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 36.4px; /* 227.5% */
		}
	}
	.ant-select-dropdown .ant-select-item-option-selected {
		background-color: unset !important;
	}

	.ant-select-focused .ant-select-selector,
	.ant-select-selector:focus,
	.ant-select-selector:active,
	.ant-select-open .ant-select-selector {
		box-shadow: none !important;
	}

	.ant-modal-confirm-content {
		padding: 0 50px;
		text-align: center;
	}
	.ant-modal-confirm-body.ant-modal-confirm-body-has-title {
		justify-content: center;
	}
}
.ant-select .ant-select-arrow {
	transition: transform 0.2s ease-in;
}
.ant-select.ant-select-open .ant-select-arrow {
	transform: rotate(180deg);
}
.ant-select-arrow {
	cursor: pointer;
}
.confirm-modal svg {
	margin: 0 !important;
}
.popup__rows {
	display: flex;
	flex-direction: column;
	align-items: end;
	.subtitle {
		color: #121212;
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px; /* 160% */
	}
	.number {
		p {
			margin: 0 !important;
		}
		color: #009e83;

		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 36.4px; /* 227.5% */
	}
	&::after {
		content: '';
		height: 1px;
		width: 60%;
		background: #009e83;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		display: block;
		position: relative;
		left: 0;
		top: 0;
	}
}
.ant-tabs-content > .ant-tabs-tabpane {
	padding-left: 0 !important;
}
@media screen and (max-width: 1050px) {
	.ticket-holders .ticket-holder__item-description .more-info__item {
		// flex-direction: column;
		gap: 0;
	}
	.ticket-holder__item-description {
		width: 100%;
	}
}
@media screen and (max-width: 480px) {
	.account-pages .account-tabs .ant-collapse-content-box {
		margin: 0;
	}
	.ticket-holders {
		.ticket-holder__item-description .more-info__item {
			// flex-direction: column;
			gap: 0;
		}
		.ticket-holders__top {
			padding: 0px 15px;
			justify-content: end;
			gap: 30px;
			align-items: start;
			flex-direction: row;
			.my-tickets__export {
				margin-top: 0 !important;
				align-self: center;
				justify-content: center;
			}
		}
	}
	.filter {
		&.ticket-holders-filter {
			width: 100%;
			left: 0;
			padding: 30px 20px;
		}
	}
	.ticket-holders {
		padding: 20px 0px !important;
		.ant-btn.add-holder {
			font-size: 13px;
			margin: 0 auto;
			gap: 0px !important;
		}
		.ticket-holder__item-column .ticket-holder__item-top {
			gap: 10px !important;
		}
		.ticket-holder__item-header {
			font-size: 18px !important;
		}
		&__top {
			flex-direction: column;
		}
		.my-tickets__export {
			margin-top: 40px;
		}
		.ticket-holder__item-columns {
			gap: 20px !important;
		}
		.flip-box {
			width: 325px !important;
		}
		.ticket-holder__item-column {
			display: flex;
			align-items: center;
			justify-content: center;
			&.description {
				align-items: start !important;
			}
		}
		.ticket-holder__item {
			padding: 0 15px;
			box-shadow: unset;
		}
		.flip-box-inner {
			width: 325px;
		}
		.flip-box {
			height: 230px;
		}
		.ticket-card__frontside,
		.ticket-card__backside {
			max-width: 325px;

			img {
				width: 100%;
			}
		}
		.ticket-holder__item-column .ticket-holder-card__container {
			display: inline;
		}

		.ticket-holder__action-columns {
			display: block !important;
		}
	}
	.ticket-holder__popup {
		&.add-ticket-holder {
			.ant-modal-body {
				padding: 0;
				margin: 0 auto;
				width: 310px;
			}
			.ant-modal-footer {
				display: flex;
				justify-content: center;
				padding: 0;
				.ant-btn {
					max-width: 150px !important;
					width: 100%;
				}
			}
		}
		&.move-balance {
			.ant-modal-footer {
				padding: 0 20px !important;
			}
			.ant-modal-body {
				padding: 0 10px !important;
			}
		}
	}
	.ticket-holder__item {
		.holder-item__more-info {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.more-info__items {
				order: 2;
			}
			.action-btns {
				justify-content: center !important;
			}
		}
	}
	&.block-balance {
		.ant-modal-footer {
			display: flex;
			justify-content: center;

			padding: 30px 10px 0 10px;
			.ant-btn {
				text-align: center;
				padding: 0px !important;
				font-size: 12px !important;
				width: 170px !important;
			}
		}
	}
	.my-balance__payment-methods {
		.my-balance__payment-method {
			border: none !important;
		}
		.ant-btn {
			width: 100% !important;
		}
		.payment-method-imgs,
		.payment-imgs {
			display: none !important;
		}
	}
	.ant-modal {
		.ant-modal-footer {
			.ant-btn:has(.ant-btn-icon.ant-btn-loading-icon) {
				span:nth-child(2) {
					display: none;
				}
			}
		}
	}
}
@media screen and (min-width: 481px) and (max-width: 576px) {
	.ticket-holders {
		padding: 30px 17px;
	}
	.ticket-holders .flip-box {
		height: 230px;
	}

	.ticket-holders .ticket-holder__item {
		padding: 0;
	}
	.ticket-holders .ticket-holder__item-column .ticket-holder-card__container {
		display: block;
	}
	.ticket-holders .ticket-holder__item {
		box-shadow: none;
	}
	.ticket-holder__action-columns {
		display: block !important;
	}
}
@media screen and (min-width: 577px) and (max-width: 767px) {
	.ticket-holders {
		padding: 30px;
		.ticket-holder__item {
			padding: 0 !important;
		}

		.ticket-holder__action-columns {
			gap: 20px;
			display: block !important;
		}
		.ticket-holder__item {
			box-shadow: none;
		}
	}
	.account-pages .account-tabs .ant-collapse-content-box {
		margin-left: 0;
	}
	.ticket-holder-card__container {
		display: block !important;
	}
	.ticket-holders .flip-box {
		height: 230px;
	}
	.more-info__data {
		.more-info__data-col p {
			max-width: unset !important;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.ticket-holders {
		padding-top: 20px !important;
		.ticket-holder__item-column .ticket-holder-card__container {
			display: block !important;
		}
		.ticket-holder__action-columns {
			display: block !important;
		}
		.ticket-holder-card__flip {
			height: 230px;
		}

		.ticket-holder__item {
			padding: 0;
			box-shadow: none !important;
		}
		.ticket-holder__item-columns {
			gap: 0px !important;
		}
	}
}
@media screen and (min-width: 992px) and (max-width: 1250px) {
	.ticket-holders .ticket-holder__item-column .ticket-holder-card__container {
		display: block !important;
	}

	.ticket-holders .flip-box {
		height: 230px;
	}
	.ticket-holders .ticket-holder__item {
		padding: 20px;
	}
	.ticket-holders .ticket-holder__actions .ticket-holder__action-columns {
		display: block;
	}

	.ticket-holder__actions-content {
		gap: 10px !important;
	}
}
@media screen and (max-width: 460px) {
	&.block-balance {
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			gap: 0 !important;
			padding: 30px 10px 0 10px;
			.ant-btn {
				text-align: center;
				padding: 0px !important;
				font-size: 12px !important;
				width: 150px !important;
			}
		}
	}
}

@media screen and (max-width: 870px) {
	.ticket-holders {
		padding: 15px;
	}
	.ticket-holder__item-columns {
		gap: 0px !important;
	}
}

@media screen and (max-width: 400px) {
	.more-info__item {
		.more-info__title {
			width: 100%;
			max-width: 250px;
			min-width: 180px !important; // Changed min-width as requested
			flex: auto 0;
			color: #121212;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 36.4px; /* 260% */
		}
	}
}

.my-balance__payment-methods {
	display: flex;
	flex-direction: column;
	gap: 10px;
	.my-balance__payment-method {
		max-height: 58px;
		display: flex;
		gap: 10px;
		padding: 10px;
		border-radius: 11px;
		border: 1px solid #009e83;
		justify-content: space-between;
		align-items: center;
		img {
			max-width: 100px;
		}
		.ant-btn {
			font-size: 12px;
			font-weight: 700;
			background-color: #009e83;
			color: #efefef;
			height: 50px;
			width: 185px;
		}
		.payment-imgs {
			display: flex;
			align-self: center;
		}
		.payment-method-imgs {
			img {
				max-width: 70px;
			}
			display: flex;
			gap: 15px;
		}
	}
}

@media screen and (min-width: 764px) and (max-width: 869px) {
	.ticket-holders .ticket-holder__item-column .ticket-holder-card__container {
		display: flex !important;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;