.partner-details {
	padding-top: 23px;
	.partner-details__row {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 0px;
	}
	.partner-details__info,
	.partner-details__contact,
	.partner-details__bank,
	.partner-details__change-pass {
		border-radius: 3px;
		background: #fff;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		padding: 40px 20px;
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	.partner-details__contact {
		.info__row {
			gap: 15px;
		}
		.info__main {
			img {
				max-width: 16px;
			}
			gap: 10px;
			display: flex;
			padding: 0 !important;
			background: transparent !important;
		}
	}
	.partner-details__bank {
		.info__main {
			padding: 0 !important;
			background: transparent !important;
		}
	}
	.partner-details__change-pass {
		.info__row {
			display: inline-flex;
			gap: 10px;
			.ant-btn {
				color: #009e83;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				border-radius: 3px;
				border: 1px solid #009e83;
				height: 57px;
			}
			.ant-btn:hover {
				background-color: #009e83;
				border-radius: 3px;
				border: 1px solid #fff;
				color: #fff;
			}
		}
	}
	.info__row {
		display: flex;
		flex-direction: column;
		gap: 6px;
		.info__title {
			p {
				margin: 0;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		}
		.info__main {
			border-radius: 3px;
			padding: 16px;
			background: #f4f4f4;
			p {
				margin: 0;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
.tab-content {
	margin-top: 0 !important;
}
.account-pages {
	.ant-tabs-content-holder {
		padding: 25px 20px 10px 20px !important;
		background-color: #fff !important;
	}
}
@media screen and (max-width: 870px) {
	.partner-details {
		padding-top: 0;
	}
}
@media screen and (max-width: 992px) {
	.partner-details__info,
	.partner-details__contact,
	.partner-details__bank,
	.partner-details__change-pass {
		padding: 20px 25px !important;
		box-shadow: none;
	}
}

@media screen and (max-width: 1050px) {
	.account-pages {
		.ant-tabs-content-holder {
			padding: 25px 30px 50px 30px !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;