.auth-modal {
	.ant-tabs-tab {
		padding-left: 0 !important;
	}
	.ant-modal-body {
		margin: 15px 43px;
	}
	.ant-row .group {
		padding: 25px 40px !important;
		margin: 12px 0px;
		background-color: #fff;
	}

	.ant-modal-title {
		color: @primary-color;
		background-color: #fff;
		font-size: clamp(14px, 3vw, 22px);
		font-weight: 700;
		padding: 25px 35px;
	}
	.ant-modal-footer {
		display: flex;
		justify-content: center;
		margin-top: 20px;
		border-top: unset;
		button {
			height: 57px;
			padding: 0 30px;
			display: flex;
			align-items: center;
			border-radius: 0;
		}
	}
	.passwordInput {
		height: 51px;
		input {
			border: unset !important;
			padding-top: 14px;
			height: 45px;
			padding-bottom: 14px;
		}
	}

	.forgot-btn {
		margin-top: 20px;
		width: 176px;
		height: 57px;
		background-color: #009e83;
		border-radius: 3px !important;
		font-size: 14px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		line-height: 19px;
		color: #ffffff;
		&:hover {
			color: #009e83;
			background-color: #ffffff;
		}
	}

	.auth-component {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		margin-top: 20px;
		cursor: default;

		&__forgotPass {
			color: #828282;
			display: flex;
			justify-content: center;
			margin-top: 15px;
			margin-bottom: 15px;
			cursor: pointer;
		}
		&__forgotPass span {
			border-bottom: 1px solid;
			position: relative;
		}
		&__forgotPass-main {
			display: flex;

			align-items: center;
		}
	}

	.auth-component-resetPass {
		color: #828282;
		text-align: center;
		margin-top: 20px;
		cursor: pointer;
	}
	.auth-component-resetPass span {
		border-bottom: 1px solid;
	}

	.auth-component span {
		margin-left: 15px;
		text-transform: uppercase;
		font-size: 14;
		font-weight: 600;
		cursor: pointer;
		color: #009e83;
	}

	input,
	.ant-select-selector,
	.ant-picker,
	.ant-input-affix-wrapper,
	.ant-upload button {
		border-radius: 3px !important;
		height: 51px;
	}
	.main-table .ant-input-affix-wrapper {
		border-radius: 0 !important;
	}
	.ant-input-group {
		.ant-input-group-addon {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		input {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
	.ant-modal-content {
		border-radius: 16px;
		background-color: #f9f9f9;
		overflow: hidden;
		padding: 0;
		padding-bottom: 30px;
		& .ant-modal-close {
			top: 25px;
			right: 35px;
			border: 1px solid #121212;
			padding: 5px;
			width: 35px;
			color: '#121212';
			height: 35px;
			border-radius: 50%;
		}
	}
	.ant-modal-header {
		border-radius: 16px 16px 0 0;
	}
	.ant-upload-disabled {
		display: none;
	}

	.resend-code__countDown {
		opacity: 0;
		transition: opacity 0.3s ease, transform 0.3s ease;
	}

	.resend-code__countDown.visible {
		opacity: 1;
		width: auto;
		transform: translateX(0);
	}

	.resend-code__countDown.invisible {
		opacity: 0;
		width: 0;

		transform: translateX(-80px);
	}
}

@media screen and (max-width: 768px) {
	.SIGN_UP {
		top: 10px !important;
		max-width: 100vw !important;
		margin: 0px !important;
		.ant-modal-body {
			margin: 15px 30px;
		}
		.ant-row .group {
			padding: 25px 20px !important;
			width: 100% !important;
		}
	}
}

@media screen and (max-width: 540px) {
	.SIGN_IN {
		.ant-modal-body {
			margin: 15px 30px;
		}
		.ant-row .group {
			padding: 25px 20px !important;
		}
	}
	.auth-component {
		flex-direction: column;
	}
	.auth-component span {
		margin-top: 10px;
	}
}
.ant-modal.SIGN_UP {
	.ant-row.ant-row-middle {
		align-items: baseline;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;