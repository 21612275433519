.form-group-title {
	color: @primary-color;
	font-size: 16px;
	margin-bottom: 15px;
}

.form-group-delete {
	border: unset !important;
	svg {
		color: @ui-red;
	}
}

.rating-button {
	.ant-radio-wrapper-checked {
		border: 1px solid @primary-color;
		border-radius: 8px;
	}
	.ant-radio-wrapper {
		padding: 5px;
	}
	.ant-radio-inner {
		border-color: @ui-black;
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;