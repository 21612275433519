.route-item-transport {
	&__item {
		.route-item-transport__top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12px;
			gap: 20px;
		}
		.route-item-transport__number {
			color: #121212;
			font-size: 20px;
			font-weight: 700;
		}
		.route-item-transport__manufacturer {
			color: #121212;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.route-item-transport__info-title {
		color: #121212;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.route-item-transport__item {
		border-radius: 3px;
		background: #fff;
		padding: 20px;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		p {
			margin: 0;
		}
	}
	.route-item-transport__content {
		display: flex;
		flex-direction: column;
		gap: 22px;
	}
	.route-item-transport__info-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	.route-item-transport__info-main {
		span {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			line-height: normal;
		}
	}
	.route-item-transport__info-item {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

@media screen and (max-width: 1100px) {
	.route-item-transport {
		&__item {
			.route-item-transport__top {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 20px;
			}
		}
		.route-item-transport__info-main {
			span {
				color: #121212;
				font-size: 14px;
				font-style: normal;
				line-height: normal;
			}
		}
		.route-item-transport__info-item {
			display: flex;
			flex-direction: column !important;
			gap: 10px;
			flex-wrap: wrap;
			justify-content: flex-start !important;
		}
	}
}
@media screen and (max-width: 630px) {
	.route-item-transport__row {
		display: flex;
		flex-direction: column;
		gap: 0px;
	}
	.route-item-transport-modal {
		.ant-modal-title {
			font-size: 16px;
			line-height: 32px;
		}
		.ant-modal-content {
			padding: 15px 15px !important;
		}
		.route-item-transport__item {
			padding: 20px 15px 30px 15px;
		}
		.route-item-transport__top {
			flex-direction: column;
		}
	}
}

@media screen and (min-width: 695px) and (max-width: 869px) {
	.route-item-transport__info-row {
		.route-item-transport__info-item {
			flex-direction: row !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;