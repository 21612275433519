.form-person-info {
	padding: 10px 60px !important;
	margin-bottom: 20px;
	border-radius: 3px;
	background: #fff;
	box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
	.ant-form-item {
		display: flex;
	}

	.ant-row .ant-form-item-row {
		align-items: center !important;
		width: 100%;
		max-width: 612px;
		align-self: flex-start;
	}
	.ant-col .ant-form-item-label {
		align-items: center !important;
		width: 612px;
		align-self: flex-start;
	}
	.ant-form-item-control-input-content {
		align-items: center;
		text-align: center;
	}
	.ant-input {
		width: 100%;
		max-width: 612px !important;
		height: 51px;
		flex-shrink: 0;
		border-radius: 3px;
		border: 0.5px solid #d3d3d3;
		background: #fff;
	}
	.ant-select,
	.ant-picker {
		width: 100%;
		max-width: 612px !important;
		height: 51px;
		flex-shrink: 0;
		border-radius: 3px;
		background: #fff;
	}
	.ant-picker,
	.ant-select-selection-search {
		height: 51px;
	}
	.labels {
		padding: 0px !important;
		margin: 0px !important;
		color: #121212;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
	label {
		color: #121212 !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
	.button-form {
		.ant-btn-default {
			align-self: center;
			flex-shrink: 0;
			width: 100%;
			max-width: 176px;
			height: 57px;
			margin: 0px !important;
			background-color: #fff;
			border-radius: 3px;
			border: 1px solid #009e83;
			color: #009e83;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}
		.ant-btn:hover {
			background-color: #009e83;
			border: 1px solid #fff;
			color: #fff;
		}
	}

	.password-btn {
		.ant-btn-default {
			width: 100%;
			max-width: 218px;
			height: 57px;
			align-self: center;
			text-align: center;
			background-color: #fff;
			border-radius: 3px;
			border: 1px solid #009e83;
			color: #009e83;
			padding: 0;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}
		.ant-btn:hover {
			background-color: #009e83;
			border: 1px solid #fff;
			color: #fff;
		}
	}
}
.top-data__rows {
	display: flex;
	gap: 10px;
	font-weight: 700 !important;
	padding: 0 0px 20px 0;
}
.ant-form-item .ant-form-item-explain-error {
	color: var(--error-color, #fe5050);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 21.56px;
	letter-spacing: 0.141px;
}
.customModal {
	width: 100%;
	max-width: 364px !important;
	padding-top: 0px !important;
	.ant-modal-content {
		width: 100%;
		justify-content: center;
		height: auto;
		border-radius: 10px;
		background: #fff;
		padding: 0px !important;

		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		.ant-modal-header {
			border-radius: 12px 12px 0px 0px;
			width: 100%;
			max-width: 364px;
			background: #fff !important;
			height: 64px;
			padding-top: 20px;
			.ant-modal-title {
				text-align: left;
				color: #121212;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				padding-left: 15px;
				line-height: 32px;
			}
		}
		.ant-modal-close {
			display: none;
		}
		.ant-modal-body {
			padding: 20px !important;
			background-color: #cccaca36 !important ;
			.ant-form-item-required {
				color: #909090;
				font-size: 14px !important;
				font-style: normal;
				font-weight: 400;
				line-height: 21.56px;
				letter-spacing: 0.141px;
			}
			.ant-input-affix-wrapper {
				height: 51px;
				border-radius: 3px;
				border: 0.5px solid #009e83;
				background: #fff;
			}
			.ant-form-item-control-input-content {
				// height: 51px;
				border-radius: 3px;
				border: 0.5px solid transparent;
				// background: #fff;
			}
			.password-criteria li {
				font-size: 10px;
				line-height: 20px;
			}
		}
		.ant-modal-footer {
			display: flex;
			justify-content: center;
			height: 70px;
			margin-top: 0px !important;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			background-color: #cccaca36 !important ;
			.ant-btn-default {
				display: inline-flex;
				padding: 20px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 3px;
				border: 1px solid #009e83;
				color: #009e83;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				&:hover {
					background-color: #009e83 !important;
					color: white !important;
					border: 1px solid #009e83 !important;
				}
			}
			.ant-btn-primary {
				display: flex;
				width: 132px;
				padding: 20px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 3px;
				background: #009e83;
				color: #f3f3f3;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				box-shadow: none;
				&:hover {
					background-color: white !important;
					color: #009e83 !important;
					border: 1px solid #009e83 !important;
				}
			}
		}
	}
}
.ant-select-selection-item {
	text-align: left !important;
}
.form-uploud {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 20px 0px 0px 0px;
	border-radius: 3px;
	background: #fff;
	box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
	.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
		align-items: flex-end;
		width: 143px;
		height: 143px;
		border: none;
		border-radius: 143px;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.camera {
		width: 42px;
		height: 22px;
		flex-shrink: 0;
		border-radius: 22.5px;
		background: rgba(255, 255, 255, 0.6);
		position: absolute;
		bottom: -30px;
		left: 30%;
		transform: translateX(-50%);
		margin-bottom: 50px;
	}
	.delete-icon {
		width: 42px;
		height: 22px;
		flex-shrink: 0;
		border-radius: 22.5px;
		background: rgba(255, 255, 255, 0.6);
		position: absolute;
		bottom: -30px;
		right: 0%;
		transform: translateX(-50%);
		margin-bottom: 50px;
	}
	.upload {
		margin-top: 40px;
		align-self: center;
	}
	.chang-images {
		color: #121212;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.upload-button {
		align-items: center;
		align-self: center;
		margin-bottom: 90px;
		.green-button {
			width: 176px;
			height: 57px;
			border-radius: 3px;
			background: #009e83;
			color: #f3f3f3;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
		}

		.gray-button {
			width: 176px;
			height: 57px;
			flex-shrink: 0;
			color: #f3f3f3;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			border-radius: 3px;
			background: #c7c7c7;
			line-height: normal;
			text-transform: uppercase;
		}
	}
	.upload-images {
		border: 1.5px solid #009e83;
		border-radius: 50%;
		width: 143px;
		height: 143px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.upload-error {
		color: red;
		margin-bottom: 10px;
		align-items: center;
		align-self: center;
		font-size: 10px;
	}
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
	background-color: #009e83;
}
.account-tabs .name,
.ant-collapse .name {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 500%;
	padding: 10px;
	font-weight: bold;
	color: #fff;
	letter-spacing: 1px;
}
@media screen and (max-width: 870px) {
	.top-data__rows {
		padding: 0 20px 20px 20px;
		flex-direction: column;
	}
}
@media screen and (max-width: 720px) {
	.form-person-info {
		margin: 0px 15px 20px 15px !important;
		padding: 10px 10px !important;
		.ant-form-item .ant-form-item-explain-error {
			font-size: 8px;
		}
		.labels {
			text-align: start !important;
		}

		.password-btn {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.button-form {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;