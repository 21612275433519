.transportCard_Back {
	.transportCard_Back__content {
		width: 325px;
		padding: 3px;
		border-radius: 12px;
		height: 213px;
		background: #00967c;
		color: #fff;
		display: flex;
		.transportCard_Back__content-custom-background {
			background-color: #000;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}
		.transportCard_Back__content-general {
			background: #00967c;
			gap: 5px;
			width: 300px;
			display: flex;
			border-radius: 12px;
			flex-direction: column;
			position: relative;
			height: 100%;
			&::after {
				position: absolute;
				content: '';
				right: 0;
				top: 50%;
				transform: translate(55%, -50%);
				background-color: #000;
				width: 35px;
				border-radius: 50%;
				height: 45px;
			}
			.transportCard_Back__content-general-top {
				display: flex;
				flex-direction: column;
				gap: 7px !important;
				.transportCard_Back__top-info {
					span {
						font-weight: 700;
					}
				}
			}
			.transportCard_Back__content-general-bottom {
				display: flex;
				gap: 10px;
				.transportCard_Back__bottom-title {
					align-self: flex-end;
				}
			}
			padding: 20px;
			padding-top: 15px;
			p {
				text-align: start;
				margin: 0 !important;
				font-size: 9px;
				opacity: 0.9;
			}
			.ant-qrcode {
				padding: 5px !important;
			}
			.transportCard_Back__id {
				flex: unset !important;
			}
			.transportCard_Back__id p {
				font-weight: 700;
				letter-spacing: 2px;
				font-size: 20px !important;
			}
		}
		.transportCard_Back__content-right-feature {
			width: 20px;
			height: 100%;
			background-color: #000;
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
		
			position: relative;
			
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				height: 90%;
				border-right: 2px dashed #00967c; /* Adjust thickness and color */
				transform: translate(-50%, -50%);
			}
			
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;