.partner-routes {
	// padding: 23px 0 0 0;
	padding-top: 20px;
	.partner-routes__content {
		display: flex;
		gap: 10px;
		flex-direction: column;
		// margin-bottom: 30px;
	}
	.partner-routes__top {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 20px;
		.my-tickets__export {
			align-self: center;
			margin-top: 0;
		}
		.defaultwrapper__filter {
			margin-bottom: 0;
		}
	}
}
@media screen and (max-width: 870px) {
	.partner-routes {
		padding: 0px;
	}
}
.partner-routes {
	.partner-routes__item-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.partner-routes__item-info {
			font-weight: 700;
			font-size: 20px;
		}
	}
	.partner-routes-row {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.drivers-modal-bottom,
	.drivers-modal-top {
		.partner-routes__item-main {
			justify-content: space-between;
		}
	}
	.partner-routes__item {
		padding: 20px;
		border-radius: 3px;
		background: #fff;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		.partner-routes__item-main {
			display: flex;
			align-items: center;

			.partner-routes__item-title {
				margin-right: 10px;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.partner-routes__item-info {
				color: #121212;
				font-size: 14px;
				text-align: end;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
.route-item-transport-modal.drivers {
	.ant-modal-title {
		color: #121212;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
}
.moreinfo__stops {
	gap: 10px;
}

.moreinfo__stop {
	color: #121212;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
}
.moreinfo__sto {
	padding: 0px 5px;
	display: flex;
	align-items: center;
}
.moreinfo__stops:first-child .moreinfo__stop::before {
	content: '';
	display: block;
	width: 30px;
	height: 30px;
	background: url('./../../../../../assets//icons/mainStop.svg') no-repeat;
}
.moreinfo__stops:last-child .moreinfo__stop::after {
	content: '';
	display: block;
	width: 30px;
	margin-left: 10px;
	height: 30px;
	background: url('./../../../../../assets/icons/destination.svg') no-repeat;
}

@media screen and (max-width: 820px) {
	.route-item-transport-modal.drivers {
		.partner-routes .drivers-modal-top {
			max-width: none;
		}
		.ant-modal-title {
			font-size: 18px;
		}

		.partner-routes__item-row.drivers-modal-bottom {
			.partner-routes__item-main {
				flex-direction: column;
				gap: 10px;
				align-items: start;
			}
		}
	}
	.partner-routes {
		.partner-routes__item {
			padding: 20px 15px 20px 15px !important;
		}
	}
}

@media screen and (max-width: 1010px) {
	.partner-routes {
		.partner-routes__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.partner-routes__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.partner-routes__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.partner-routes__item-info {
			margin: 10px 0px;
		}
	}
}
@media screen and (max-width: 780px) {
	.partner-routes {
		.partner-routes__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.partner-routes__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.partner-routes__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.partner-routes__item-info {
			margin: 10px 0px;
		}
	}
}

@media screen and (min-width: 695px) and (max-width: 869px) {
	.partner-routes__item-row {
		.partner-routes__item-main {
			align-items: center !important;
		}
	}
	.partner-routes__item-main {
		width: 100%;
		// align-items: center !important;
		flex-direction: row !important;
		.partner-routes__item-info {
			// 	align-items: center !important;
			margin: 0 !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;