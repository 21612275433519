.transportCard_Front {
	user-select: none;
	.transportCard_Front__content {
		width: 325px;
		height: 213px;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #00967c;
		padding: 3px;
		.transportCard_Front__content-top {
			padding: 20px 0 3px 20px;
			display: flex;
		}
		.transportCard_Front__left {
			height: 100%;
			padding-bottom: 3px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			.transportCard_Front__card-title {
				p {
					text-align: left;
					font-weight: 600;
					color: #fff;
					font-size: 13px;
				}
			}
			.transportCard_Front__left-bottom {
				.transportCard_Front__nfc-logo {
					display: flex;
					width: 40px;
				}
				.transportCard_Front__nfc-logo img {
					width: 100%;
				}
			}
			.transportCard_Front__webpage-logo {
				width: 50px;
				height: 50px;
	
			}
		}
		.transportCard_Front__road {
			height: 35px;
			width: 100%;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			background-color: #000;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				border-radius: 3px;
				border-top: 3px dashed #00967c; /* Adjust thickness and color */
				transform: translateY(-50%);
			}
		}
	}
	.qr-code{
		.qr-code-container {
			display: inline-block;
			padding: 5px; /* Adjust as needed */
		  }
		  
		  .qr-code svg rect {
			fill: #00ff00 !important; /* Green squares */
			stroke: #000000 !important; /* Black lines */
			stroke-width: 1 !important; /* Adjust line thickness if needed */
		  }
		  
		  .qr-code svg rect:nth-child(odd) {
			fill: #00ff00 !important; /* Green squares */
		  }
		  
		  .qr-code svg rect:nth-child(even) {
			fill: #00ff00 !important; /* Green squares */
		  }
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;