@font-face {
	font-family: 'MontserratArm';
	src: local('MontserratArm'), url(fonts/MontserratArm/Montserratarm-Regular.otf) format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'MontserratArm';
	src: local('MontserratArm'), url(fonts/MontserratArm/Montserratarm-Bold.otf) format('truetype');
	font-weight: bold;
	font-style: normal;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'MontserratArm', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	font-family: 'MontserratArm';
}
/* SCROLL */
/* ::-webkit-scrollbar {
	width: 8px;
	height: 16px;
	position: absolute;
	right: 0;
	top: 0;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 30px;
	-webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
} */
