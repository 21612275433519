.main__ticket-container {
	gap: 8px;
	flex-direction: column;
	align-items: start;
	display: flex;
	margin-bottom: 20px;
	padding: 15px 20px 35px 20px;
	border-radius: 10px;
	background: #f9f9f9;
	.ant-divider {
		margin: 20px 0;
	}
	.ticket__trips-count,
	.ticket__storage-key,
	.ticket__not-used-trips,
	.ticket__storage-type,
	.ticket__price {
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;
		display: inline-flex;
		p {
			margin: 0;
		}
		.price {
			p {
				color: #009e83;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
			}
		}
	}
	.ticket__storage-header {
		flex: unset !important;
	}
	.ticket__trips-header,
	.ticket__storage-header,
	.ticket__not-used-trips-header,
	.ticket__storage-key-header,
	.ticket__price-header {
		color: #121212;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;

		p {
			margin: 0;
		}
	}
	.ticket__trips-count {
		p {
			margin: 0;
		}
	}
	.ticket__valid {
		display: flex;
		flex-direction: column;
		p {
			margin: 0;
		}
		.ticket__valid-till,
		.ticket__valid-purchasedate {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			display: flex;
			gap: 30px;
		}
	}
}
@media screen and (max-width: 480px) {
	.ticket__trips-count,
	.ticket__storage-key,
	.ticket__not-used-trips,
	.ticket__storage-type,
	.ticket__price {
		display: unset !important;
	}
	.ticket__valid-till,
	.ticket__valid-purchasedate {
		flex-direction: column;
		gap: 0px !important;
	}
}


@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;