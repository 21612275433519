.payment-history {
	padding: 20px 0 0;
	.payment-history__top {
		display: flex;
		// align-items: center;
		// margin-bottom: 30px;
		margin-top: 0px;
		.my-tickets__export {
			align-self: center;
			// padding-left: 10px;
		}
		.defaultwrapper__filter {
			margin-bottom: 0;
		}
	}

	.payment-history__subitem-date {
		border-radius: 3px;
		background: #f0f0f0;
		padding: 15px 30px;
		box-shadow: 0px 20px 20px 0px rgba(18, 18, 18, 0.03);
		color: #9e9e9e;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
	}
	.payment-history__item {
		border-radius: 3px;
		background: #fff;
		padding: 30px 50px;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		.payment-history__item-title {
			color: #121212;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 36.4px;
			p {
				margin-top: 0;
			}
		}
		.subitem-moreinfo-item {
			display: flex;
			align-items: center;
			gap: 20px;
			.subitem-moreinfo-item__title {
				flex: auto 0;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 36.4px;
				p {
					margin: 0;
				}
			}
		}
	}
	.payment-history__rows {
		display: flex;
		flex-direction: column;
		gap: 22px;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 400px) {
	.payment-history .payment-history__item .subitem-moreinfo-item {
		display: unset !important;
	}
}
@media screen and (max-width: 480px) {
	.payment-history {
		padding: 20px 10px;
		.payment-history__item {
			box-shadow: none;
			padding: 10px;
			// margin: 0 -15px;
		}

		.subitem-moreinfo__rows {
			padding: 5px;
			background-color: #f9f9f9;
		}
		.subitem-moreinfo-item {
			gap: 5px !important;
		}
		.subitem-moreinfo-item__title {
			p {
				font-size: 12px;
			}
		}
	}
}
@media screen and (min-width: 481px) and (max-width: 992px) {
	.payment-history {
		padding: 20px 0px 100px 0px;
		.payment-history__item {
			padding: 15px 25px;
		}
	}
}
@media screen and (max-width: 870px) {
	.payment-history__top {
		padding: 0px 15px;
		.my-tickets__export {
			align-self: center;
			// padding-left: 10px;
		}
		.defaultwrapper__filter {
			margin-bottom: 0;
		}
	}
}
@media screen and (max-width: 870px) {
	.payment-history {
		padding: 0 0px !important;
	}
}

.payment-history {
	padding: 20px 0 0px 0;
	.payment-history__content {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	.payment-history__top {
		display: flex;
		gap: 20px;
		justify-content: end;
		// align-items: center;
	}
	.my-tickets__export {
		margin-top: 0;
		align-self: center;
	}
	.defaultwrapper__filter {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 870px) {
	.payment-history__top {
		padding: 0px 15px;
	}
	.payment-history {
		padding: 44px 0px 0px 0px !important;
		.route-item-transport__item {
			padding: 36px 15px !important;
		}
	}
}

.payment-history {
	.payment-history__item {
		.payment-history__top {
			display: flex;
			justify-content: space-between;
			// align-items: center;
			margin-bottom: 12px;
			gap: 20px;
		}
		.payment-history__number {
			// padding-bottom: 15px;
			color: #121212;
			font-size: 20px !important;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		.payment-history__manufacturer {
			color: #121212;
			font-size: 20px !important;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.payment-history__info-title {
		color: #121212;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.payment-history__item {
		border-radius: 3px;
		background: #fff;
		padding: 20px;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		p {
			margin: 0;
		}
	}
	.payment-history__content {
		display: flex;
		flex-direction: column;
		gap: 22px;
	}
	.payment-history__info-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	.payment-history__info-main {
		span {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			line-height: normal;
		}
	}
	.payment-history__info-item {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

@media screen and (max-width: 1100px) {
	.payment-history {
		&__item {
			.payment-history__top {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 20px;
			}
		}
		.payment-history__info-main {
			span {
				color: #121212;
				font-size: 14px;
				font-style: normal;
				line-height: normal;
			}
		}
		.payment-history__info-item {
			display: flex;
			flex-direction: column !important;
			gap: 10px;
			flex-wrap: wrap;
			justify-content: flex-start !important;
		}
	}
}
@media screen and (max-width: 630px) {
	.payment-history__row {
		display: flex;
		flex-direction: column;
		gap: 0px;
	}
	.payment-history-modal {
		.ant-modal-title {
			font-size: 16px;
			line-height: 32px;
		}
		.ant-modal-content {
			padding: 15px 15px !important;
		}
		.payment-history__item {
			padding: 20px 15px 30px 15px;
		}
		.payment-history__top {
			flex-direction: column;
		}
	}
}

@media screen and (min-width: 695px) /* and (max-width: 1024px) */ {
	.payment-history {
		.payment-history__info-item {
			width: 100%;

			flex-direction: row !important;
			align-items: center !important ;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;