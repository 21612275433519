.footer {
	background-color: #fff;
	padding: 65px 0 0 0;
	font-family: 'MontserratArm';
	.footer__links-items__contact {
		display: flex;
		.footer__links-item {
			margin-bottom: 0 !important	;
		}
	}

	.footer__links-items__news {
		margin-bottom: 10px;
		position: relative;
		left: 15px;
	}
}
.footer_content {
	max-width: 1300px;
	padding: 20px 30px;
	margin: 0 auto;
}
.footer_transport_bottom-columns {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.footer_transport_images_box-row {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.footer_transport_images_box {
	display: flex;
	width: 100%;
	max-width: 450px;
}
.footer_transport_paragraph {
	width: 100%;
	max-width: 1010px;
	p {
		margin-top: 0;
		margin-bottom: 2px;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0em;
		line-height: 16px;
		text-align: left;
	}
}
.foother__icon__phone {
	display: flex;
}
.footer_transport_bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.footer_transport_version {
		display: flex;
	}

	.footer_transport_am {
		font-size: 12px !important;
		color: #909090;
		margin: 0;
		margin-right: 10px;
	}
	.footer_version {
		margin: 0;
		color: #909090;
		font-size: 12px !important;
	}
	.footer_smartsoft_am {
		font-size: 12px !important;
		height: auto;
		color: #909090;
	}
	.footer_smartsoft_am_box {
		margin-top: 10px;
	}
	.foother__phone_number {
		display: flex;
		flex-direction: column;
	}
}

@media screen and (max-width: 1200px) {
	.foother__icon__phone {
		display: flex;
		flex-direction: column !important;
	}
	.footer_content {
		max-width: 1300px;
		padding: 20px 20px !important;
		margin: 0 auto;
	}
}
@media screen and (max-width: 1100px) {
	.foother__icon__phone {
		display: flex;
		justify-content: space-between !important;
		flex-wrap: wrap !important;
		width: 100%;
		flex-direction: row !important;
	}
	.foother__phone_number {
		display: flex;
		margin-top: 10px;
		flex-direction: row;
		flex-wrap: wrap !important;
	}
}
@media screen and (max-width: 700px) {
	.other-info-footer {
		display: flex;
		gap: 20px;
	}
	.div-footer {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
@media screen and (max-width: 480px) {
	.footer_transport_bottom {
		display: flex;
		flex-direction: column;
		.footer_transport_version {
			display: flex;
			flex-direction: column !important;
		}
		.footer_transport_version {
			margin-bottom: 0px !important;
		}
		.footer_smartsoft_am_box {
			margin-top: 0px !important;
		}
	}
	.footer {
		.footer__links-items__contact {
			display: block;
		}
		// padding: 40px 0 15px 0;
	}
	.footer__columns {
		flex-direction: column;
		margin: 0 auto;
	}
	.footer__columns::after {
		order: 3;
		content: '';
		display: block;
		margin: 20px 0;
		height: 1px;
		width: 100%;
		background-color: #121212;
	}
	.footer__links-first,
	.footer__links-second .footer__links-items {
		flex-direction: column;
	}
	.footer__columns .footer__links-first .footer__links-items {
		margin-right: 0;
		margin-bottom: 15px;
	}
	.footer .footer__columns {
		margin-bottom: 0;
	}
	.footer__links-item.social {
		display: flex;
	}
	.footer__logo {
		width: 100px !important;

		margin: 0 0 40px 0 !important;
		height: 45px;
		& img {
			max-width: 100%;
		}
	}
	.footer_divider {
		display: none;
	}

	.footer__links-item.social > .footer__link-social {
		margin: 0 40px 0 0;
	}
	.footer__links-first > :first-child {
		margin-bottom: 15px;
	}
	.footer__links-second {
		order: 2;
	}
	.footer__links-item.social {
		margin-top: 30px;
		display: flex;
		width: 200px;
		order: 1;
		img {
			zoom: 150%;
		}
	}
	.footer__columns .footer__links-item.social > :not(:last-child) {
		margin-bottom: 0;
	}
	.footer__links-first > .footer__links-items:nth-child(2):after,
	.footer_transport_bottom {
		display: flex;
		flex-direction: column;
		.footer_transport_version {
			display: flex;
			flex-direction: column;
		}
	}
}
@media screen and (min-width: 480px) and (max-width: 575px) {
	.footer_transport_version {
		display: flex;
		flex-direction: column !important;
	}
	.footer__links-items__contact {
		display: block;
	}
	.footer_transport_version {
		margin-bottom: 0px !important;
	}
	.footer_smartsoft_am_box {
		margin-top: 0px !important;
	}
	.footer .footer__columns {
		margin-bottom: 30px;
	}

	.footer__links-items {
		margin-top: 20px;
	}
	.footer__links-item.social {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 150px;
		img {
			zoom: 150%;
		}
	}
}
@media screen and (min-width: 576px) and (max-width: 767px) {
	.footer_transport_bottom {
		display: flex;
		flex-direction: column !important;
		.footer_transport_version {
			display: flex;
			flex-direction: column !important;
		}
		.footer_transport_version {
			margin-bottom: 0px !important;
		}
		.footer_smartsoft_am_box {
			margin-top: 0px !important;
		}
	}
	.footer {
		padding-top: 65px;
		.footer__links-items__contact {
			display: block;
		}
	}
	.footer__links-item.social {
		display: flex;
		justify-content: space-around;
		width: 150px;
		margin: 20px 0 0 0;
		img {
			zoom: 150%;
		}
	}
	.footer .footer__columns {
		flex-direction: column;
		margin-bottom: 20px;
	}
	.footer__logo {
		width: 150px;
		height: 70px;
		margin-bottom: 20px;
	}
	.footer__links-first {
		padding-bottom: 20px;
	}
	.footer__links-item.social {
		display: flex;
		& > .footer__link-social:not(:last-child) {
			margin-right: 10px;
		}
	}
	.footer__links-second {
		padding-top: 20px;
	}
	.footer .footer__links-first .footer__links-items {
		margin-right: 30px;
		margin-top: 10px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.footer_transport_bottom {
		display: flex;
		flex-direction: column !important;
		.footer_transport_version {
			margin-bottom: 0px !important;
		}
		.footer_smartsoft_am_box {
			margin-top: 0px !important;
		}
	}
	.footer__links-first {
		margin: 0px 0 20px 0;
	}
	.footer__logo {
		width: 150px;
		height: 70px;
		margin-bottom: 20px;
	}
	.footer__links-item.social {
		display: flex;
		gap: 20px;
		img {
			zoom: 150%;
		}
	}
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
	.footer__logo {
		margin-bottom: 20px;
	}
	.footer__links-item.social {
		display: flex;
		gap: 20px;
		img {
			zoom: 150%;
		}
	}
}
@media screen and (max-width: 992px) {
	.footer_transport_version {
		flex-direction: column;
	}
	.footer_transport_bottom {
		flex-direction: column;
	}
}
.footer__columns {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	height: max-content;
	justify-content: space-between;
	margin-bottom: 50px;
}
.footer__logo {
	width: 150px;
	height: 70px;
	margin-right: 10px;
	margin-bottom: 90px;
}
.footer__links-first,
.footer__links-second .footer__links-items {
	display: flex;
	flex-wrap: wrap;
}
.footer__link a {
	color: #000;
}
.footer__links-first .footer__links-items {
	margin-right: 65px;
}
.footer__links-second .footer__links-item:first-child {
	margin-right: 40px;
}
.footer__links-second .footer__links-items:first-child .footer__link {
	margin-bottom: 15px;
}
.footer__links-item.social > :not(:last-child) {
	margin-bottom: 9px;
}

.footer__links-item.ico {
	display: flex;
	& span {
		margin-right: 11px;
	}
}
.footer__links-item:not(:last-child),
.footer__links-item p {
	margin-top: 0;
	margin-bottom: 15px;
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;