.defaultwrapper__filter {
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
	.button-filter-images {
		position: relative;
		border-radius: 3px;
		border: 1px solid #009e83;
		display: flex;
		align-items: center;
		gap: 15px;
		padding: 25px 10px;
		color: #009e83;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
		background-color: white;
	}
	.button-filter-images:hover {
		background-color: #009e83 !important;
		color: white !important;
	}
}

.ant-dropdown:has(.filter) {
	z-index: 10 !important;
}

.filter {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	border-radius: 10px;
	background: #f0f0f0;
	padding: 0px 30px 20px 30px;
	width: 380px;
	box-shadow: 0px 0px 15px 5px rgba(18, 18, 18, 0.151);

	.default__wrapper__btn {
		display: flex;
		justify-content: center;
	}
	.ant-col.ant-form-item-label {
		label {
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
		}
	}
	.ant-picker {
		height: 50px;
		border: none;
		.ant-picker-suffix {
			display: none;
		}
	}
	.ant-form-item {
		margin-bottom: 10px !important;
	}
	.filter__header {
		margin-bottom: 10px;
		height: 57px;
		border-bottom: 1px solid #009e83;
		color: var(--main-black, #121212);
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: 36.4px;
	}
	.ant-row {
		display: flex;
		flex-direction: column;
	}
	.ant-col {
		width: 100%;
		max-width: 350px !important;
	}
	.ant-form-item-required {
		color: var(--main-black, #121212);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 36.4px;
	}
	.ant-select {
		outline: none;
		input {
			min-height: 50px;
		}

		&-selection-item {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		&-selector {
			border: none !important;
		}
	}
	.ant-input {
		border: none;
		outline: none;
	}
	.ant-btn {
		align-items: flex-end;
		align-self: flex-end;
		color: #f3f3f3;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
		width: 150px;
		margin: 10px;
		height: 47px;
		border-radius: 3px;
		background: #009e83;
		&:hover {
			background-color: white;
			color: #009e83;
		}
	}
	.reset {
		background-color: white;
		color: #009e83;
		border: 1px solid #009e83;
		&:hover {
			background-color: #009e83;
			color: white !important;
		}
	}
	.ant-btn:disabled {
		background: #9e9e9e;
	}

	.rc-virtual-list-holder-inner {
		.ant-select-item {
			background-color: transparent;
			padding: 10px 20px;
			border-radius: 0;
		}
		.ant-select-item:not(:last-child) {
			border-bottom: 1px solid #009e83;
		}
	}
	.ant-tabs-content > .ant-tabs-tabpane {
		padding-left: 0 !important;
	}
}

@media screen and (max-width: 870px) {
	.filter {
		position: relative;
		left: 10px;
		width: 45vw !important;
	}
}
@media screen and (max-width: 680px) {
	.filter {
		position: relative;
		left: 20px;
		width: 60vw !important;
		margin-right: 15px;
	}
}
@media screen and (max-width: 525px) {
	.filter {
		.default__wrapper__btn {
			align-items: center;
			display: flex;
			flex-direction: column-reverse !important;
			justify-content: center;
			.ant-btn {
				align-items: flex-end;
				align-self: flex-end;
				color: #f3f3f3;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				width: 100%;
				max-width: 270px !important;
				margin: 10px;
				margin-right: 0px !important;
				height: 47px;
				border-radius: 3px;
				background: #009e83;
				&:hover {
					background-color: white;
					color: #009e83;
				}
			}
			.reset {
				background-color: white;
				color: #009e83;
				border: 1px solid #009e83;
				&:hover {
					background-color: #009e83;
					color: white !important;
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.filter {
		position: relative;
		left: 20px;
		width: 70vw !important;
		.default__wrapper__btn {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;