.ticket__transports {
	display: flex;
	gap: 15px;
	align-items: center;

	.ticket__transports-items {
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		align-self: flex-start;
		display: flex;
		gap: 10px;
		padding: 10px 0px;
	}
}
.ticket__transports-header {
	flex: unset !important;
	display: flex;
	flex-direction: column;
	p {
		margin: 0 !important;
	}
	transition: 0.3s;
	color: #121212;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 36.4px;
}
.qr_modal_button {
	padding: 0 !important;
	&.ant-btn-default:hover {
		border-color: transparent !important;
	}
}
.transports-item {
	p {
		line-height: 30px;
	}
	height: 30px;
	max-width: 30px;
	min-width: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 1px solid #009e83;
	border-radius: 50%;
}
.owned-ticket {
	border-radius: 3px;
	background: #fff;
	box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
	.owned-ticket__rows {
		padding: 20px;
	}
	.owned-ticket__header {
		color: #121212;
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: 36.4px;
	}
	&__main {
		display: flex;
		gap: 55px;
		.main__ticket-container {
			max-width: 438px;
			min-width: 300px;
			border: 0.5px solid #009e83;
		}
		.owned-tikcet__qrcode {
			align-self: center;
			max-width: 230px;
			.qr_expired {
				img {
					opacity: 0.2;
				}
				position: relative;
				z-index: 9;
				.qr_expired-date {
					z-index: 999;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
				}
			}
			img {
				max-width: 100%;
			}
		}
	}
	.owned-ticket-description {
		&__header {
			color: #121212;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 36.4px;
		}
		&__main {
			color: #121212;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.14px;
		}
	}
}
@media screen and (max-width: 1150px) {
	.owned-ticket {
		&__main {
			gap: 0px !important;
			.main__ticket-container {
				min-width: 0 !important;
			}
			flex-direction: column !important;
			.owned-tikcet__qrcode {
				align-self: center;
				max-width: 170px !important;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.main__ticket-container {
		width: 100%;
		max-width: 400px;
		min-width: 300px;
		border: 0.5px solid #009e83;
	}
	.owned-ticket {
		padding: 0px;
	}
	.owned-ticket__rows {
		padding: 0px !important;
	}
}

@media screen and (max-width: 360px) {
	.ticket__transports-items {
		padding: 0px !important;
	}
}

@media screen and (min-width: 695px) and (max-width: 869px) {
	.owned-ticket {
		.owned-ticket__main {
			gap: 20px !important;
			flex-direction: row !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;