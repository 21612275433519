.route-drivers {
	.route-drivers__item-row {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.route-drivers__item-info {
			font-weight: 700;
			font-size: 20px;
		}
	}
	.route-drivers-row {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.drivers-modal-bottom,
	.drivers-modal-top {
		.route-drivers__item-main {
			justify-content: space-between;
		}
	}
	.route-drivers__item {
		padding: 20px 20px 20px 20px;
		border-radius: 3px;
		background: #fff;
		box-shadow: 10px 20px 50px 0px rgba(18, 18, 18, 0.05);
		.route-drivers__item-main {
			display: flex;
			align-items: center;

			.route-drivers__item-title {
				margin-right: 10px;
				color: #121212;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.route-drivers__item-info {
				color: #121212;
				font-size: 14px;
				text-align: end;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}
.route-item-transport-modal.drivers {
	.ant-modal-title {
		color: #121212;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
}

@media screen and (max-width: 820px) {
	.route-item-transport-modal.drivers {
		.route-drivers .drivers-modal-top {
			max-width: none;
		}
		.ant-modal-title {
			font-size: 18px;
		}

		.route-drivers__item-row.drivers-modal-bottom {
			.route-drivers__item-main {
				flex-direction: column;
				gap: 10px;
				align-items: start;
			}
		}
	}
	.route-drivers {
		.route-drivers__item {
			padding: 20px 15px 20px 15px !important;
		}
	}
}

@media screen and (max-width: 1010px) {
	.route-drivers {
		.route-drivers__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.route-drivers__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.route-drivers__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.route-drivers__item-info {
			margin: 10px 0px;
		}
	}
}
@media screen and (max-width: 780px) {
	.route-drivers {
		.route-drivers__item-main {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
		}
		.route-drivers__item-row {
			justify-content: flex-start;
			align-items: flex-start;
		}
		.route-drivers__item-main {
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		.route-drivers__item-info {
			margin: 10px 0px;
		}
	}
}
@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;