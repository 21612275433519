.not-found {
	padding: 30px 0;
	.not-found__gif {
		max-width: 330px;
		img {
			max-width: 100%;
		}
	}
	.not-found__rows {
		.not-found__row {
			display: flex;
			justify-content: center;
			align-items: center;
			&.bottom {
				flex-direction: column;
				.not-found__header {
					p {
						font-size: 27px;
						color: #009e83;
					}
				}
				.not-found__subtitle {
					max-width: 900px;
					text-align: center;
					p {
						font-family: 'GHEA Grapalat', Arial, sans-serif;
						font-size: 30px;
					}
				}
				.ant-btn {
					display: flex;
					align-items: center;
					height: 57px;
					padding: 0 30px;
					font-weight: 700;
					line-height: 19px;
					text-transform: uppercase;
					background-color: #009e83;
					color: #fff;
				}
			}
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;