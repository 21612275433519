.page-pagination {
	width: 100%;
	display: flex;
	justify-content: end;
	flex-wrap: wrap;
	padding-bottom: 20px;
	align-items: end !important;
	cursor: pointer;

	.totalItems {
		align-items: center;
		align-self: center;
		width: 200px !important;
		min-width: 200px !important;
		gap: 20;
	}

	.ant-pagination {
		display: flex;
		align-items: end;
		justify-content: flex-end;
		cursor: pointer;

		.ant-pagination-options {
			font-size: 11px !important;
			cursor: pointer !important;
		}

		.ant-pagination-prev,
		.ant-pagination-item,
		.ant-pagination-next,
		.ant-pagination-options {
			font-size: 11px !important;
			cursor: pointer !important;
		}

		.ant-select-item-option {
			font-size: 11px !important;
		}
	}
}

.ant-select-dropdown .ant-select-item-option-content {
	font-size: 13px !important;
	cursor: pointer !important;
}

@media screen and (max-width: 1194px) {
	.page-pagination {
		padding: 0px 20px !important;
		gap: 20;

		.selects {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
		}

		.totalItems {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
			min-width: 200px !important;
		}
	}
}

@media screen and (max-width: 870px) {
	.page-pagination {
		.selects {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
		}

		.totalItems {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
			min-width: 200px !important;
			gap: 20;
		}
	}
}

@media screen and (max-width: 780px) {
	.page-pagination {
		.selects {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
		}

		.totalItems {
			display: flex;
			width: 100%;
			justify-content: start !important;
			align-items: end;
			align-self: center;
			min-width: 200px !important;
			gap: 20;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;