.account-pages {
	width: 100%;
	margin-bottom: 150px;
	.link-tab {
		color: #909090;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
	}
	.link-active-tab {
		color: #909090;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
	}
	.testtt {
		display: inline-flex;
		align-items: center;
	}
	.text {
		display: inline-block;
		width: 100%;
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.item-content.inactive {
		display: none;
	}
	.acount-title {
		color: #121212;
		font-style: normal;
		font-weight: 900;
		text-transform: capitalize;
		font-size: 32px;
	}
	.ant-tabs-nav-wrap {
		overflow: unset !important;
	}
	.account-tabs {
		width: 100%;
		overflow: hidden;
		.ant-tabs-nav {
			min-width: 368px;
			max-width: 368px;
			margin-right: 40px;
			background-color: transparent;
		}
		.ant-tabs-tab {
			min-width: 368px;
			max-width: 368px;
			border-radius: 3px;
			background: #fff !important;
			box-shadow: 0px 20px 20px 0px rgba(18, 18, 18, 0.03);
			display: flex;
			align-items: center;
			justify-content: left;
			padding: 8px 0px;
			max-height: 87px !important;
			min-height: 87px !important;
		}
		.ant-tabs-tab-active {
			background-color: #fff !important;
			min-width: 408px;
			z-index: 1;
			text-align: left;
		}

		.ant-tabs-content-holder {
			background-color: #fff !important;
			height: auto;
			border-left: none;
			z-index: 1;
			margin: 0px !important;
		}
		.spanss-arro {
			margin-left: 10px;
		}
		.ant-tabs-tab.ant-tabs-tab-active::after {
			content: '';
			background-color: transparent;
			box-shadow: -10px -2px 0px 0px #fff;
			position: absolute;
			border-radius: 50%;
			margin-right: -45px;
			bottom: -10px;
			right: 0;
			width: 52px;
			height: 105px;
			z-index: 0 !important;
		}
		.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
			text-align: left;
		}
		.ant-tabs .ant-tabs-ink-bar {
			background-color: transparent !important;
		}

		.ant-collapse-borderless {
			width: 100%;
			max-width: 722px !important;
			background: #fff !important;
			border-bottom: none !important;
		}
		.ant-collapse > .ant-collapse-item {
			border-radius: 3px;
			background: #fff;
			border: transparent;
			box-shadow: 0px 20px 20px 0px rgba(18, 18, 18, 0.03);
			margin-top: 20px;
		}
		.ant-collapse-header {
			padding: 0px 40px;
			margin: 0;
			border-bottom: none !important;
			background-color: #fff !important;
		}
		.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
			padding: 0px !important;
		}
		.account-tabs-div {
			display: flex;
			justify-content: space-between;
			max-width: 360px;
			padding: 0px 20px !important;
		}
	}

	.ant-tabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
		margin-left: 10px;
		text-align: left;
		color: #121212;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 600;
		text-transform: capitalize;
	}
	.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		margin-left: 10px;
		color: #009e83;
		font-size: 14px !important;
	}
}

.ican-notification {
	display: flex;
	background: #fe5050;
	min-width: 28px;
	min-height: 28px;
	border-radius: 50%;
	color: white;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.item-labelss {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	align-items: center;
	align-self: center;
	width: 100%;
	max-width: 722px;
	height: 87px;
	border-radius: 3px;
	background: #fff;

	.items-box-label {
		display: flex;
		align-items: center;
	}
	.item-title {
		margin-left: 10px;
		color: #121212;
		margin-left: 12px;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 16.4px;
		text-transform: capitalize;
	}
	.button-ican-notic {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}
.ant-picker-time-panel {
	display: none !important;
}

@media screen and (max-width: 950px) {
	.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
		padding: 16px 0px;
	}
	.account-pages {
		.ant-tabs-nav-wrap {
			height: 700px;
			overflow: unset !important;
		}
		.account-tabs {
			width: 100%;
			.ant-tabs-nav {
				padding: 0;
				min-width: 280px !important;
				max-width: 280px !important;
				margin-right: 40px;
				background-color: transparent !important;

				border-radius: 3px !important;
			}

			.ant-tabs-tab {
				min-width: 280px !important;
				max-width: 280px !important;
				height: 87px;
				border-radius: 3px !important;
				background: #fff !important;
				box-shadow: 0px 20px 20px 0px rgba(18, 18, 18, 0.03);
				display: flex;
				align-items: center;
				justify-content: left;
				padding: 8px 0px;
				max-height: 87px !important;
			}
			.account-tabs-div {
				padding: 0px 2px !important;
			}
			.ant-tabs-tab-active {
				min-width: 320px !important;
			}

			.ant-tabs-content-holder {
				padding: 10px 20px 20px 20px !important;
			}
			.ant-tabs-tab.ant-tabs-tab-active::after {
				position: absolute;
				border-radius: 50%;
				margin-right: -60px;
				bottom: -9px;
				right: 0;
				width: 72px;
				height: 105px;
			}
			.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
				text-align: left;
			}
			.ant-tabs .ant-tabs-ink-bar {
				background-color: transparent;
			}
		}

		.ant-tabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
			margin-left: 10px;
			font-size: 12px !important;
		}
		.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			margin-left: 10px;
			font-size: 12px !important;
		}

		.ant-collapse-item {
			margin-top: 10px !important;
			border-bottom: none !important;
		}
		.ant-collapse-item .ant-collapse-item-active {
			margin: 0px !important;
			background-color: #fff !important;
		}
		.ant-collapse-content-box {
			background-color: #fff !important;
		}
		.ant-collapse-header {
			background-color: #fff !important;
			padding: 0px 16px !important;
		}
	}
}
@media screen and (max-width: 480px) {
	.account-pages {
		.acount-title {
			color: #121212;
			font-size: 22px;
			font-style: normal;
			font-weight: 900;
			line-height: 26px !important;
			text-transform: capitalize;
		}
	}
}
@media screen and (max-width: 400px) {
	.account-pages {
		.ant-collapse > .ant-collapse-item > .ant-collapse-header {
			padding: 0px 10px !important;
			margin: 0;
			border-radius: 3px !important;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;